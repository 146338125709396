import dayjs from 'dayjs'

function isBookingInactive (booking, tz) {
  const item = booking
  if (!item) return false
  var checkin = dayjs(dayjs(item.checkin).tz(tz).format('YYYY/MM/DD')).valueOf()
  var checkout = dayjs(dayjs(item.checkout).tz(tz).format('YYYY/MM/DD')).valueOf()
  var now = dayjs(dayjs().tz(tz).format('YYYY/MM/DD')).valueOf()
  return checkout
    ? !(now >= checkin && now <= checkout)
    : !now >= checkin
}

export {
  isBookingInactive
}
