<template>
  <v-layout v-if="booking" align-center wrap class="grey--text text--darken-2 headline my-1 flex-none">
    <span class="show-spaces">{{ (isSameDay ? $t('bookings.valid_on') : $t('bookings.valid_from')) + ' ' }}</span>
    <span :class="{ 'red--text text--lighten-1': inactive, 'mr-4': !checkout || isSameDay }">{{ checkin.format('DD/MM/YYYY') }}</span>
    <template v-if="checkout && !isSameDay">
      <span class="show-spaces">{{ ' ' + $t('bookings.valid_to') + ' ' }}</span>
      <span :class="{ 'red--text text--lighten-1': inactive }" class="mr-4">
        {{ checkout.format('DD/MM/YYYY') }}
      </span>
    </template>
    <v-icon v-if="inactive" color="red" size="20" class="mr-3">mdi-alert-outline</v-icon>
  </v-layout>
</template>

<script>
import { isBookingInactive } from '@/utils/BookingUtils'
import { mapState } from 'vuex'
import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
export default {
  props: {
    booking: Object
  },
  data () {
    return {
      dayjs
    }
  },
  computed: {
    ...mapState({
      parkFacility: state => state.facilities.read
    }),
    inactive () {
      return isBookingInactive(this.booking, this.parkFacility.timezone)
    },
    checkin () {
      return this.booking && this.booking.checkin ? dayjs(this.booking.checkin).tz(this.parkFacility.timezone) : null
    },
    checkout () {
      return this.booking && this.booking.checkout ? dayjs(this.booking.checkout).tz(this.parkFacility.timezone) : null
    },
    isSameDay () {
      return dayjs(this.checkout).isSame(dayjs(this.checkin), 'day')
    }
  }
}
</script>
